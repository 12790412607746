import { buildResultWorkOrderFormState } from "@/builder";
import { generateUUID } from "@/helpers/uuid";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { ResultWorkOrderStatusEnum } from "@/models/enums/result-work-order.enum";
import {
  ResultWorkOrderCreateRequestDto,
  ResultWorkOrderFormState,
  ResultWorkOrderFormUnitState,
  ResultWorkOrderLineRequestDto,
  ResultWorkOrderResponseDto,
} from "@/models/interface/result-work-order";
import { WorkOrderResponseDto } from "@/models/interface/work-order";
import moment from "moment";

export class ResultWorkOrderMapper {
  private static isResultWorkOrderResponseDto(
    arg: any
  ): arg is ResultWorkOrderResponseDto {
    return typeof arg === "object" && "resultWorkOrderLines" in arg;
  }

  private static isWorkOrderResponseDto(arg: any): arg is WorkOrderResponseDto {
    return typeof arg === "object" && "workOrderLines" in arg;
  }

  static toResultWorkOrderFormState(
    dto: ResultWorkOrderResponseDto
  ): ResultWorkOrderFormState;
  static toResultWorkOrderFormState(
    dto: WorkOrderResponseDto
  ): ResultWorkOrderFormState;
  static toResultWorkOrderFormState(dto: unknown): ResultWorkOrderFormState {
    const state: ResultWorkOrderFormState = buildResultWorkOrderFormState();
    if (this.isWorkOrderResponseDto(dto)) {
      state.workOrderId = dto.id;
      state.workOrderNumber = dto.documentNumber;
      state.workOrderDate = moment(dto.workOrderDate).format(
        DEFAULT_DATE_FORMAT
      );
      state.picName = dto.picName.trim();
      state.customerName = dto.customerName;
      state.customerId = dto.customerId;
      state.customerAddress = dto.customerAddress;
      state.mechanicId = dto.mechanicId;
      state.mechanicName = dto.mechanicName;
      state.scheduleOrder = moment(dto.scheduleOrder).format(
        DEFAULT_DATE_FORMAT
      );
      state.description = dto.description;
      state.products = dto.workOrderLines.map((item, index) => ({
        key: generateUUID(),
        no: index + 1,
        unitCode: item.unitCode,
        serialNumber: item.serialNumber,
        category: item.assetCategory,
        type: item.type,
        productReserved: item.productName,
        productReservedId: item.productId,
        productReservedQty: item.qtyWorkOrder,
        uom: item.productUomName,
        returnedQty: 0,
        partNumber: item.partNumber,
        isTrackAsAsset: false,
        workOrderLineId: item.id,
      }));

      return state;
    }

    if (this.isResultWorkOrderResponseDto(dto)) {
      state.workOrderId = dto.id;
      state.workOrderNumber = dto.workOrderNumber;
      state.workOrderDate = moment(dto.workOrderDate).format(
        DEFAULT_DATE_FORMAT
      );
      state.picName = dto.picName.trim();
      state.customerMaintenanceNumber = dto.documentNumber;
      state.csfNumber = dto.workOrderCSFNumber;
      state.customerName = dto.customer;
      state.customerAddress = dto.customerAddress;
      state.mechanicName = dto.mechanic;
      state.scheduleOrder = moment(dto.scheduledOrder).format(
        DEFAULT_DATE_FORMAT
      );
      state.description = dto.description;
      state.journalNumber = dto.journalNumber;
      state.journalId = dto.journalId;
      state.status = dto.status;
      state.reason = dto.reason;
      state.completeDate = dto.completeDate ? moment(dto.completeDate) : null;
      state.notes = dto.notes;
      state.products = dto.resultWorkOrderLines.map((item, index) => ({
        key: generateUUID(),
        no: index + 1,
        unitCode: item.unitCode,
        serialNumber: item.serialNumber,
        category: item.assetCategory,
        type: item.type,
        productReserved: item.productReservedName,
        productReservedId: item.productReservedId,
        productReservedQty: item.qtyReserved,
        uom: item.uomReservedName,
        returnedQty: item.qtyReturned,
        partNumber: item.partNumber,
        isTrackAsAsset: item.trackAsAsset,
        workOrderLineId: item.id,
      }));
      return state;
    }

    return buildResultWorkOrderFormState();
  }

  static toCreateLineRequest(
    state: ResultWorkOrderFormUnitState
  ): ResultWorkOrderLineRequestDto {
    const request: ResultWorkOrderLineRequestDto = {
      qtyReturned: state.returnedQty,
      trackAsAsset: state.isTrackAsAsset,
      workOrderLineId: state.workOrderLineId,
    };

    return request;
  }

  static toCreateRequest(
    state: ResultWorkOrderFormState
  ): ResultWorkOrderCreateRequestDto {
    const request: ResultWorkOrderCreateRequestDto = {
      completeDate: state.completeDate ? state.completeDate.format() : "",
      customerId: state.customerId,
      description: state.description ?? "",
      mechanicId: state.mechanicId,
      notes: state.notes,
      picName: state.picName ?? "",
      reason: state.reason ?? "",
      resultWorkOrderLineDTOS: state.products.map(this.toCreateLineRequest),
      scheduledOrder: moment(state.scheduleOrder).format(),
      status: state.status ?? ("" as ResultWorkOrderStatusEnum),
      workOrderId: state.workOrderId,
      customerAddress: state.customerAddress ?? "",
    };

    return request;
  }
}
